import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "~components/layout/Default"
import { Arrow } from "~components/atoms/Arrow"
import { getImage, getParallaxFactor, translate } from "~utility/Sink"
import { FullWidthImage } from "~components/elements/FullWidthImage"

import * as styles from "~styles/templates/product.module.scss"
import { SEO } from "~components/Seo"
import { ContactBox } from "~components/elements/ContactBox"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { ContentSection } from "~components/atoms/ContentSection"
import { Headline } from "~components/atoms/Headline"
import { ExhibitionTeaser } from "~components/elements/ExhibitionTeaser"
import { Accordion } from "~components/atoms/Accordion"
import { RteText } from "~components/atoms/RteText"
import { ResponsiveIframe } from "~components/atoms/ResponsiveIframe"
import { Text } from "~components/atoms/Text"
import { ProductsGrid } from "~components/elements/ProductsGrid"

export const query = graphql`
    query ProductPage($uid: String!, $lang: String!) {
        contacts: allPrismicContact(
            filter: {
                lang: { eq: $lang }
                data: { role: { eq: "Sales Department" } }
            }
        ) {
            edges {
                node {
                    id
                    data {
                        address {
                            html
                        }
                        email {
                            text
                        }
                        name {
                            text
                        }
                        role
                        telephone {
                            text
                        }
                        title {
                            text
                        }
                    }
                }
            }
        }
        divisions: allPrismicDivision {
            edges {
                node {
                    id
                    data {
                        name {
                            text
                        }
                        sales_contact {
                            document {
                                ... on PrismicContact {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
        product: prismicProduct(uid: { eq: $uid }, lang: { eq: $lang }) {
            uid
            lang
            type
            url
            alternate_languages {
                uid
                type
                lang
                url
            }
            data {
                name {
                    text
                }
                hero_name {
                    text
                }
                main_image {
                    url
                }
                division {
                    document {
                        ... on PrismicDivision {
                            id
                        }
                    }
                }
                banner_image_1 {
                    dimensions {
                        height
                        width
                    }
                    fluid(maxWidth: 2000, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                banner_image_2 {
                    dimensions {
                        height
                        width
                    }
                    fluid(maxWidth: 2000, maxHeight: 600) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                teaser {
                    text
                }
                logo {
                    url
                }
                text {
                    html
                }
                features {
                    html
                }
                processes {
                    html
                }
                options {
                    html
                }
                video {
                    html
                }
                video_file {
                    url
                }
                icons {
                    image {
                        url
                    }
                    description {
                        text
                    }
                }
                shortcut
                products {
                    product {
                        document {
                            ... on PrismicProduct {
                                id
                                data {
                                    main_image {
                                        url
                                    }
                                    name {
                                        text
                                    }
                                    logo {
                                        url
                                    }
                                    categories {
                                        category {
                                            slug
                                            document {
                                                ... on PrismicCategory {
                                                    id
                                                    data {
                                                        name {
                                                            text
                                                        }
                                                        sort_order
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    teaser {
                                        text
                                    }
                                    icons {
                                        image {
                                            url
                                        }
                                        description {
                                            text
                                        }
                                    }
                                }
                                lang
                                uid
                                type
                            }
                        }
                    }
                }
            }
        }
        exhibitions: allPrismicExhibition(sort: { fields: data___date_begin }) {
            edges {
                node {
                    lang
                    data {
                        division {
                            document {
                                ... on PrismicDivision {
                                    id
                                }
                            }
                        }
                        date_begin(locale: "de")
                        date_end(locale: "de")
                        name {
                            text
                        }
                        text {
                            text
                        }
                        link {
                            url
                        }
                        booth_number
                        address {
                            text
                        }
                        logo {
                            url
                        }
                    }
                }
            }
        }
    }
`

const ProductPageHead = ({ product }) => {
    const [scrollY, setScrollY] = useState(0)

    const setScrollPos = () => {
        requestAnimationFrame(() => {
            setScrollY(window.scrollY)
        })
    }

    useEffect(() => {
        document.addEventListener("scroll", setScrollPos)

        return () => {
            document.addEventListener("scroll", setScrollPos)
        }
    }, [])

    return (
        <div className={styles.headSection}>
            <img
                className={styles.headSectionLogo}
                src={product.logo.url}
                alt=""
            />
            <div className={styles.headSectionImage}>
                <div>
                    <img
                        alt=""
                        style={{
                            transform: `translateY(-${getParallaxFactor(
                                scrollY,
                                0,
                                30,
                                0,
                                300
                            )}px)`,
                        }}
                        src={getImage(product.main_image.url, {
                            width: 1800,
                            height: 1200,
                        })}
                    />
                </div>
                <div>
                    <Headline
                        variant="h1"
                        style={{
                            transform: `translateY(${getParallaxFactor(
                                scrollY,
                                0,
                                20,
                                0,
                                300
                            )}px)`,
                        }}
                    >
                        {product.hero_name.text
                            ? product.hero_name.text
                            : product.name.text}
                    </Headline>
                </div>
            </div>

            <p>{product.teaser.text}</p>

            <Arrow />
        </div>
    )
}

const Product = ({ data }) => {
    const product = data.product.data
    const division = data.divisions.edges.find(
        item => item.node.id === product.division.document.id
    )
    const contact = data.contacts.edges.find(
        item => item.node.id === division.node.data.sales_contact.document.id
    ).node.data

    const { lang, type, url } = data.product || {}
    const alternateLanguages = data.product.alternate_languages || []
    const activeDocMeta = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const exhibition = data.exhibitions.edges
        .filter(item => item.node.lang === lang)
        .filter(
            item =>
                new Date(item.node.data.date_begin) >= new Date() ||
                (new Date(item.node.data.date_begin) <= new Date() &&
                    new Date(item.node.data.date_end) >= new Date())
        )
        .filter(
            item =>
                item.node.data.division.document?.id ===
                product.division.document?.id
        )
        .slice(0, 1)[0]?.node?.data

    return (
        <DefaultLayout activeDocMeta={activeDocMeta}>
            <SEO
                title={product.name.text}
                description={product.teaser.text}
                lang={lang}
            />

            <div>
                <ProductPageHead product={product} />

                {product.banner_image_1.fluid && (
                    <FullWidthImage image={product.banner_image_1} />
                )}

                <ContentSection className={styles.textSection}>
                    <GridRow width="content">
                        <GridColumn s={12} m={8} l={8} gutterBottom>
                            <div style={{ maxWidth: 670 }}>
                                <RteText
                                    gutterBottom="large"
                                    content={product.text.html}
                                />

                                {product.processes.html && (
                                    <Accordion
                                        borderBottom={!product.options.html}
                                        headline={translate(
                                            "product.accordion_processes",
                                            lang
                                        )}
                                    >
                                        <RteText
                                            content={product.processes.html}
                                        />
                                    </Accordion>
                                )}

                                {product.options.html && (
                                    <Accordion
                                        borderBottom
                                        headline={translate(
                                            "product.accordion_options",
                                            lang
                                        )}
                                    >
                                        <RteText
                                            content={product.options.html}
                                        />
                                    </Accordion>
                                )}
                                {product.video && product.video.html && (
                                    <div className={styles.textSectionVideo}>
                                        <ResponsiveIframe
                                            html={product.video.html}
                                        />
                                    </div>
                                )}

                                {product.video_file && product.video_file.url && (
                                    <div className={styles.textSectionVideo}>
                                        <video controls>
                                            <source
                                                src={product.video_file.url}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </div>
                                )}
                            </div>
                        </GridColumn>
                        <GridColumn s={12} m={4} l={4}>
                            <ContactBox
                                language={lang}
                                item={{
                                    headline: contact.name.text,
                                    subline: contact.title.text,
                                    address: contact.address.html,
                                    phone: contact.telephone.text,
                                    email: contact.email.text,
                                }}
                            />

                            {exhibition && (
                                <ExhibitionTeaser
                                    language={lang}
                                    item={exhibition}
                                />
                            )}
                        </GridColumn>
                    </GridRow>
                </ContentSection>

                {product.banner_image_2.fluid && (
                    <FullWidthImage image={product.banner_image_2} />
                )}

                <ContentSection className={styles.featuresSection}>
                    <GridRow width="content">
                        <GridColumn s={12} m={8} l={7}>
                            <Headline variant="h2">
                                {translate(
                                    "product.headline.features_and_benefits",
                                    lang
                                )}
                            </Headline>
                            <RteText content={product.features.html} />
                        </GridColumn>
                        <GridColumn s={12} m={4} l={5}>
                            {product.icons &&
                                product.icons.find(
                                    el => el.image.url.length > 0
                                ) && (
                                    <GridRow width="content">
                                        <div className={styles.icons}>
                                            {product.icons.map((icon, i) => (
                                                <div key={i}>
                                                    <div>
                                                        <img
                                                            src={icon.image.url}
                                                            alt={
                                                                icon.description
                                                                    .text
                                                            }
                                                        />
                                                    </div>
                                                    <Text>
                                                        {icon.description.text}
                                                    </Text>
                                                </div>
                                            ))}
                                        </div>
                                    </GridRow>
                                )}
                        </GridColumn>
                    </GridRow>
                </ContentSection>

                {product.products && (
                    <div className={styles.relatedProductsSection}>
                        <Headline variant="h2">
                            {translate(
                                "product.headline.related_products",
                                lang
                            )}
                        </Headline>
                        <ProductsGrid
                            activeDocMeta={activeDocMeta}
                            showFilter={false}
                            products={product.products
                                .map(item => item.product)
                                .map(item => ({
                                    node: item.document,
                                }))}
                        />
                    </div>
                )}
            </div>
        </DefaultLayout>
    )
}

export default Product
