// extracted by mini-css-extract-plugin
export var fadeIn = "g_H";
export var featuresSection = "g_M";
export var featuresSectionImage = "g_N";
export var headSection = "g_F";
export var headSectionImage = "g_J";
export var headSectionLogo = "g_G";
export var icons = "g_Q";
export var relatedProductsSection = "g_P";
export var textSection = "g_K";
export var textSectionVideo = "g_L";