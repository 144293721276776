import React from "react"
import { Button } from "~components/atoms/Button"
import { Headline } from "~components/atoms/Headline"

import * as styles from "~styles/elements/teaser/exhibition-teaser.module.scss"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { localizeRoute, translate } from "~utility/Sink"
import { navigate } from "gatsby"

export function ExhibitionTeaser({ language, item }) {
    return (
        <div className={styles.root}>
            <Headline hasLine>
                {translate("exhibition_teaser.visit_us", language)}
            </Headline>

            <div className={styles.image}>
                <img src={item.logo.url} alt={item.name.text} />
            </div>

            <div className={styles.text}>
                <time>
                    <LocalizedDate date={item.date_begin} />
                    &nbsp;&ndash;&nbsp;
                    <LocalizedDate date={item.date_end} />
                </time>
                <Headline variant="h5" gutterBottom={false}>
                    {item.name.text}
                </Headline>
                <address
                    dangerouslySetInnerHTML={{ __html: item.address.text }}
                />
            </div>

            <Button
                withArrow
                theme="dark"
                onClick={() =>
                    navigate(localizeRoute("newsroom.exhibitions", language))
                }
            >
                {translate("exhibition_teaser.all_exhibitions", language)}
            </Button>
        </div>
    )
}
